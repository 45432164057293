<template>
  <div class="wameed-dashboard-page-content">
    <page-header
      :title="$t('subscriptions.title')"
      :sub-title=" $t('subscriptions.desc')"
    />


    <section
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column">
            <w-tables
              v-if="getAllSubscriptions"
              :per-page="filterData.per_page"
              :items="getAllSubscriptions"
              :fields="fields"
              :status="status"
              :custimized-items="[
                { name: 'action' },
                { name: 'total_amount' },
                { name: 'total_subscriptions' },
              ]"
            >
              <template slot="total_subscriptions" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3"
                    >{{ data.item.total_subscriptions?data.item.total_subscriptions:0 }} {{ $t('common.subscriber') }}</span
                  >
                </div>
              </template>

              <template slot="total_amount" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3"
                    >{{ data.item.total_amount?data.item.total_amount:0 }} {{ $t('common.SR') }}</span
                  >
                </div>
              </template>

              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                  variant="background"
                  toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                  menu-class="rounded-14"
                  no-caret
                  dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon class="mx-3 my-1" />
                  </template>
                  <b-dropdown-item
                    link-class="py-0min-height: 52px;"
                    @click="packageDetails(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <eyeon-icon class="mx-2" />
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </w-tables>
            <wameed-no-data
              v-if="getAllSubscriptions && getAllSubscriptions.length < 1"
              icon="nodata-icon"
              :title="$t('packages.no_data')"
              :sub-title="$t('packages.no_data_text')"
            />
          </div>
        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
            v-if="getTotalSubscriptions"
            v-model="filterData.page"
            :page="filterData.page"
            :total-items="getTotalSubscriptions.totalItems"
            :per_page="filterData.per_page"
            @changePage="changePage"
            @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WTables from '@/components/pages/applicants/WTables.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    WTables,
  },
  data() {
    return {
      filterData: {
        page: 1,
        per_page: 20,
      },
      fields: [
        {
          key: 'title',
          label: this.$i18n.t('packages.package_name'),
          sortable: true,
        },
        {
          key: 'total_amount',
          label: this.$i18n.t('table.total_amount'),
          sortable: true,
        },
        {
          key: 'total_subscriptions',
          label: this.$i18n.t('table.total_subscriptions'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$i18n.t('packages.status'),
          sortable: true,
        },
        { key: 'action', label: '' },
      ],
      status: [
        {
          active: 'active',
          not_active: 'not_active',
          soon: 'soon',
          expired: 'expired',
          not_available: 'not_available',
        },
        {
          active: 'success',
          soon: 'warning',
          not_active: 'danger',
          expired: 'danger',
          not_available: 'danger',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllSubscriptions: 'getAllSubscriptionPackages',
      getTotalSubscriptions: 'getTotalSubscriptionPackages',
    }),
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadSubscriptions: 'loadSubscriptionsPackages',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadSubscriptions(this.filterData);
    },
    packageDetails(id) {
      this.$router.push({
        name: 'subscriptionDetail',
        params: { lang: this.$i18n.locale, id },
      });
    },

  },
};
</script>
