var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content"},[_c('page-header',{attrs:{"title":_vm.$t('subscriptions.title'),"sub-title":_vm.$t('subscriptions.desc')}}),_c('section',{staticClass:"\n      wameed-dashboard-page-content_body\n      d-flex\n      flex-column\n      justify-content-between\n      overflow-hidden\n    "},[_c('div',{staticClass:"d-flex flex-wrap flex-1 px-2"},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table d-flex flex-column"},[(_vm.getAllSubscriptions)?_c('w-tables',{attrs:{"per-page":_vm.filterData.per_page,"items":_vm.getAllSubscriptions,"fields":_vm.fields,"status":_vm.status,"custimized-items":[
              { name: 'action' },
              { name: 'total_amount' },
              { name: 'total_subscriptions' } ]},scopedSlots:_vm._u([{key:"total_subscriptions",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(data.item.total_subscriptions?data.item.total_subscriptions:0)+" "+_vm._s(_vm.$t('common.subscriber')))])])]}},{key:"total_amount",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(data.item.total_amount?data.item.total_amount:0)+" "+_vm._s(_vm.$t('common.SR')))])])]}},{key:"action",fn:function(ref){
            var data = ref.data;
return [_c('b-dropdown',{attrs:{"variant":"background","toggle-class":"text-decoration-none rounded-10 px-2 py-2 ","menu-class":"rounded-14","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('vertical-dots-icon',{staticClass:"mx-3 my-1"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"link-class":"py-0min-height: 52px;"},on:{"click":function($event){return _vm.packageDetails(data.item.id)}}},[_c('span',{staticClass:"text-regular-14 text-font-secondary"},[_c('eyeon-icon',{staticClass:"mx-2"}),_vm._v(" "+_vm._s(_vm.$t('common.show_details'))+" ")],1)])],1)]}}],null,false,3729606090)}):_vm._e(),(_vm.getAllSubscriptions && _vm.getAllSubscriptions.length < 1)?_c('wameed-no-data',{attrs:{"icon":"nodata-icon","title":_vm.$t('packages.no_data'),"sub-title":_vm.$t('packages.no_data_text')}}):_vm._e()],1)]),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[(_vm.getTotalSubscriptions)?_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotalSubscriptions.totalItems,"per_page":_vm.filterData.per_page},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}}):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }